<template>
  <div class="home">
    <div class="banner">
      <div class="container">
        <div class="columns is-vcentered flex-direction-column-reverse">
          <div class="column is-half">
            <div class="bannertext handdiv">
              <!-- <h1>On demand lawn mowing without contracts</h1> -->
              <div class="height-50 align-content-center">
                <h1>Your home needs made easy!</h1>
              </div>
              <div class="height-50 align-content-center">
                <h2 class="second-text">
                  Book On-Demand and Instant Home Services with Local Experts
                  through Our App.
                </h2>
              </div>
              <div class="height-50">
                <h3 class="third-text">
                  On demand lawn mowing currently available
                </h3>

                <div class="appstores">
                  <a
                    href="https://apps.apple.com/in/app/nestease/id1539350208"
                    target="_blank"
                    ><img src="@/assets/images/appstore.png" alt=""
                  /></a>

                  <a
                    href="https://play.google.com/store/apps/details?id=com.nestease.customer"
                    target="_blank"
                    ><img src="@/assets/images/playstore.png" alt=""
                  /></a>
                </div>
              </div>
            </div>
          </div>

          <div class="column is-half handimg">
            <img src="@/assets/images/home/hand1.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="howitworks">
      <div class="is-hidden-desktop">
        <h2 class="has-text-centered">How the app works</h2>

        <div class="mobile steps">
          <div class="columns is-mobile is-vcentered">
            <div class="count">1</div>
            <div class="column">
              <div class="step">Select Your Address</div>
            </div>
          </div>

          <div class="columns is-mobile is-vcentered">
            <div class="count">2</div>
            <div class="column"><div class="step">Enter Lot Size</div></div>
          </div>

          <div class="columns is-mobile is-vcentered">
            <div class="count">3</div>
            <div class="column">
              <div class="step">Select Date of Service</div>
            </div>
          </div>

          <div class="columns is-mobile is-vcentered">
            <div class="count">4</div>
            <div class="column"><div class="step">Done!</div></div>
          </div>
        </div>
      </div>
      <div class="columns is-vcentered">
        <div class="column imgcol">
          <!-- <img src="@/assets/images/demo.png" alt="" /> -->
          <!-- 
                         <video
                              src="@/assets/video/video1.mp4"
                              controls
                              loop
                              autoplay
                              style="width:350px;border:none !important"
                              type="video/mp4"
                              ref="video"
                         ></video> -->

          <!-- <Canvas-video
                              src="https://nestease-website.web.app/media/video1.a5009c67.mp4"
                              :autoplay="true"
                              style="height:1000px;;"
                              :loop="true"
                              :squeare="true"
                         ></Canvas-video> -->

          <video-background
            :src="require('@/assets/video/video1.mp4')"
            :poster="require('@/assets/images/demo.png')"
            style="height: 640px; width: 320px; display: inline-block"
          >
            <!-- <h1 style="color: white;">Hello welcome!</h1> -->
          </video-background>
        </div>
        <div class="column textcol is-hidden-touch">
          <h2>How the app works</h2>

          <div class="steps">
            <div class="columns is-mobile is-vcentered">
              <div class="count">1</div>
              <div class="column">
                <div class="step">Select Your Address</div>
              </div>
            </div>

            <div class="columns is-mobile is-vcentered">
              <div class="count">2</div>
              <div class="column"><div class="step">Enter Lot Size</div></div>
            </div>

            <div class="columns is-mobile is-vcentered">
              <div class="count">3</div>
              <div class="column">
                <div class="step">Select Date of Service</div>
              </div>
            </div>

            <div class="columns is-mobile is-vcentered">
              <div class="count">4</div>
              <div class="column"><div class="step">Done!</div></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="why">
      <h2>Why nestease?</h2>

      <div class="container">
        <div>
          <p class="subtext">
            We empower customers to select customizable lawn care services as
            needed with a wide range of local and community providers.
          </p>
        </div>

        <div class="columns is-multiline itemscontainer">
          <div class="column is-6 itemwhy">
            <div class="columns is-mobile is-vcentered">
              <div class="iconcol">
                <img
                  src="@/assets/images/home/contract.svg"
                  class="whyicon"
                  alt=""
                />
              </div>

              <div class="column textcol">
                <h3>No Contracts</h3>
                <p>Customers can request services as needed!</p>
              </div>
            </div>
          </div>

          <div class="column is-6 itemwhy">
            <div class="columns is-mobile is-vcentered">
              <div class="iconcol">
                <img
                  src="@/assets/images/home/realtime.svg"
                  class="whyicon"
                  alt=""
                />
              </div>

              <div class="column textcol">
                <h3>Real Time Updates</h3>
                <p>Payments, Messaging and Tracking</p>
              </div>
            </div>
          </div>
          <div class="column is-6 itemwhy">
            <div class="columns is-mobile is-vcentered">
              <div class="iconcol">
                <img
                  src="@/assets/images/lawn-mower-3.png"
                  class="whyicon lawnmowericon"
                  alt=""
                />
              </div>

              <div class="column textcol">
                <h3>Qualified Partners</h3>
                <p>No Pre-visits and Contactless</p>
              </div>
            </div>
          </div>

          <div class="column is-6 itemwhy">
            <div class="columns is-mobile is-vcentered">
              <div class="iconcol">
                <img
                  src="@/assets/images/home/community.svg"
                  class="whyicon"
                  alt=""
                />
              </div>

              <div class="column textcol">
                <h3>Help Communities</h3>
                <p>
                  Empowering Small Businesses, Local Providers and Freelancers
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="illustration"></div>

    <div class="quotes">
      <div class="container">
        <svg
          width="42"
          height="31"
          viewBox="0 0 42 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M41.3254 18.5902H32.4863L38.3791 30.3757H29.5399L23.6471 18.5902V0.911865H41.3254V18.5902Z"
            fill="white"
          />
          <path
            d="M17.7555 0.911377V18.5897H8.91632L14.8092 30.3752H5.96998L0.0771582 18.5897V0.911377H17.7555Z"
            fill="white"
          />
        </svg>

        <b-carousel :arrow="false" :pause-info="false">
          <b-carousel-item>
            <div class="quote">
              Thank you nestease! It was very hard for us to get someone to mow
              our yard while we were out on vacation. No providers want to do
              the job for one time. We were able to request the service from the
              beach.

              <div class="name">Sarah Ambert</div>

              <!-- <div class="avatar">
                                        <img src="@/assets/images/avatar.png" alt="" />
                                   </div> -->
            </div>
          </b-carousel-item>
          <b-carousel-item>
            <div class="quote">
              Our lawn looks so beautiful! Nestease did a great job ensuring
              mowing, trimming and cleaning. We highly recommend it for our
              neighbours and friends.

              <div class="name">The Weinsteen Family</div>

              <!-- <div class="avatar">
                                        <img src="@/assets/images/avatar.png" alt="" />
                                   </div> -->
            </div>
          </b-carousel-item>
          <b-carousel-item>
            <div class="quote">
              Love the ease of use of this App. Took only a few minutes to set
              it up. Now I am able to request lawn mowing when I need.

              <div class="name">Dan Upstein</div>
              <!-- 
                                   <div class="avatar">
                                        <img src="@/assets/images/avatar.png" alt="" />
                                   </div> -->
            </div>
          </b-carousel-item>

          <b-carousel-item>
            <div class="quote">
              Nestease provided the ability for us to not only request lawn
              mowing services as needed but also helped make the payment made
              real time. Saved a lot of time from tracking and mailing the
              payments. We are falling in love with this product! Will recommend
              it for others.

              <div class="name">The Hernadez Family</div>

              <!-- <div class="avatar">
                                        <img src="@/assets/images/avatar.png" alt="" />
                                   </div> -->
            </div>
          </b-carousel-item>
        </b-carousel>
      </div>
    </div>

    <div class="download container">
      <h2 class="is-hidden-mobile">Your Need. Your Time. Your Choice.</h2>
      <h2 class="is-hidden-tablet" style="font-size: 40px">
        Your Need.<br />
        Your Time.
        <br />
        Your Choice.
      </h2>
      <p>Service just 2 clicks away</p>

      <div class="appstores">
        <a
          href="https://apps.apple.com/in/app/nestease/id1539350208"
          target="_blank"
          ><img src="@/assets/images/appstore.png" alt=""
        /></a>

        <a
          href="https://play.google.com/store/apps/details?id=com.nestease.customer"
          target="_blank"
          ><img src="@/assets/images/playstore.png" alt=""
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    // this.$refs.video.play();
  },
};
</script>

<style scoped lang="scss">
.home {
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

.banner {
  min-height: 700px;
  padding: 50px 0 100px;
  background: white;

  @media screen and (max-width: 704px) {
    padding: 10px 0 100px;
  }

  h1 {
    font-size: 47px;
    line-height: 60px;
    @media screen and (max-width: 904px) {
      font-size: 35px;
      line-height: 40px;
    }
  }
  .border {
    height: 12;
    border: 1px solid black;
  }
  .flex-direction-column-reverse {
    display: flex;
    @media screen and (max-width: 913px) {
      flex-direction: column-reverse;
    }
  }
  .handdiv {
    text-align: left;
    align-items: center;
    align-content: center;
    height: 600px;
    width: auto;

    @media screen and (max-width: 913px) {
      text-align: center;
      padding: 10px;
      height: auto;
      width: 100%;
      display: block;
      margin: auto;
    }
  }
  .handimg {
    text-align: right;
    img {
      height: 600px;
      width: auto;
    }

    @media screen and (max-width: 913px) {
      text-align: center;
      padding: 10px 0px;
      img {
        height: auto;
        width: 100%;
        display: block;
      }
    }
  }

  // @media screen and (max-width: 1024px) {
  //      background: url("../assets/images/home/bannerhand.jpg");

  //      background-repeat: no-repeat;
  //      background-position: center center;
  //      height: 500px;
  // }
  // .container {
  //      @media screen and (max-width: 1024px) {
  //           align-items: center;
  //           display: flex;
  //           flex-direction: column;
  //           justify-content: center;
  //           height: 100%;
  //      }
  // }
  .bannertext {
    .height-50 {
      padding: 50px 0px;
    }
    .align-content-center {
      align-content: center;
    }
    .second-text {
      font-size: 30px;
      line-height: 35px;
      @media screen and (max-width: 904px) {
        font-size: 25px;
        line-height: 35px;
      }
    }
    .third-text {
      font-size: 30px;
      line-height: 35px;
      // font-weight: 600;
      color: black;
      @media screen and (max-width: 904px) {
        font-size: 25px;
        line-height: 35px;
      }
    }
    @media screen and (max-width: 1024px) {
      .height-50 {
        // padding: 20px 0;
      }
    }
  }

  p {
    margin-top: 30px;
    font-family: "DM SANS";
    font-size: 20px;
    color: black;
    .location {
      svg {
        position: relative;
        top: 5px;
      }
      // font-family: "DM SANS";
      font-weight: 600;
      color: black;
    }
  }

  .appstores {
    margin-top: 35px;

    @media screen and (max-width: 704px) {
      margin-top: 10px;
      margin-bottom: 20px;
    }

    img {
      height: 50px;
      width: auto;
      margin-right: 10px;

      @media screen and (max-width: 704px) {
        height: 47px;
        width: auto;
      }
    }
  }
}

.howitworks {
  padding: 100px 0 150px;
  @media screen and (max-width: 900px) {
    padding-top: 80px;
  }

  .imgcol {
    text-align: right;
    padding-right: 20px;

    @media screen and (max-width: 1200px) {
      text-align: center;
      padding-right: 0;
    }
  }
  .textcol {
    padding-left: 50px;
  }

  .steps {
    margin-top: 30px;
    .columns {
      margin-bottom: 25px;
    }
    .count {
      background: #03cf92;
      color: white;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      font-size: 23px;
      font-weight: 600;
      font-family: "DM Sans", sans-serif;
    }
    .step {
      padding-left: 20px;
      font-weight: 600;
      font-size: 23px;

      @media screen and (max-width: 900px) {
        font-size: 21px;
      }
    }
  }

  .mobile.steps {
    width: 300px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 45px;
  }
}

div.why {
  h2 {
    text-align: center;
  }
  .container {
    max-width: 1000px;
  }

  @media screen and (max-width: 700px) {
    padding: 20px;
  }
  .subtext {
    max-width: 600px;
    margin: auto;
    text-align: center;
    margin-top: 20px;
  }

  .itemscontainer {
    max-width: 950px;
    margin: auto;
    margin-right: -50px;

    @media screen and (max-width: 1000px) {
      margin-right: 0px;
    }

    .itemwhy {
      margin-top: 60px;

      // border: 1px solid red;

      .iconcol {
        text-align: right;
        .lawnmowericon {
        }

        .whyicon {
          width: 100%;
          max-width: 80px;
          height: auto;
        }
      }

      .textcol {
        padding-left: 30px;

        p {
          max-width: 300px;
          margin-top: 10px;
        }
      }
    }
  }
}

.illustration {
  margin-top: 30px;
  background: url("../assets/images/background-illustration.png");
  height: 500px;
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;

  @media screen and (max-width: 900px) {
    height: 300px;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.quotes {
  background: #0fcb92;
  padding: 150px 0;
  margin-top: -4px;

  text-align: center;

  .quote {
    min-height: 280px;
    color: white;
    max-width: 700px;
    margin: auto;
    margin-top: 20px;
    font-weight: 600;
    font-size: 24.7989px;
    padding-bottom: 20px;

    @media screen and (max-width: 900px) {
      font-size: 20px;
    }

    .name {
      font-size: 20px;
      margin: 20px;
    }
  }
}

.download {
  h2 {
    @media screen and (max-width: 900px) {
      font-size: 30px;
    }
  }
  @media screen and (max-width: 900px) {
    padding: 100px 20px;
  }
  text-align: center;
  padding: 150px 0;

  p {
    font-size: 20px;
    margin-top: 10px;
    font-weight: 600;
  }

  .appstores {
    margin-top: 30px;
    img {
      height: 50px;
      width: auto;
      margin-right: 10px;
    }
  }
}
</style>
